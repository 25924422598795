import "../App.css";
import { expositors } from "./expositoresList";
const Expositores = () => {
  function isEven(n) {
    return n % 2 === 0;
  }

  const imageSection = (item) => {
    if (!item.image) return null;
    return (
      <div class="wp-block-column" id="nastasia">
        <div class="wp-block-column">
          <div class="wp-block-image is-style-rounded">
            <figure class="aligncenter size-thumbnail">
              <img
                loading="lazy"
                width="150"
                height="150"
                src={item.image}
                alt=""
                class="wp-image-302"
                sizes="(max-width: 150px) 100vw, 150px"
              />
            </figure>
          </div>
        </div>
      </div>
    );
  };

  const dataSection = (item) => (
    <div class="wp-block-column">
      <h4>{item.name}</h4>
      <p>{item.description}</p>
    </div>
  );

  const handleOrder = (index, item) => {
    const isPair = isEven(index + 1);
    if (isPair)
      return (
        <>
          {imageSection(item)}
          {dataSection(item)}
        </>
      );
    return (
      <>
        {dataSection(item)}
        {imageSection(item)}
      </>
    );
  };

  return (
    <div>
      <br />
      <h1 class="has-text-align-left">EXPOSITORES</h1>
      {expositors.map((item, index) => {
        return (
          <>
            {
              <div key={item.name} class="wp-block-query">
                <hr class="wp-block-separator" />
                <ul class="wp-block-post-template">
                  <div class="wp-block-columns">{handleOrder(index, item)}</div>
                </ul>
              </div>
            }
          </>
        );
      })}
    </div>
  );
};

export default Expositores;
