import "./App.css";
import "./assets/css.css";
import "./assets/css2.css";
import "./assets/print.css";
import "./assets/style.css";
import "./assets/style.min.css";
import "./assets/wpfront-scroll-top.min.css";
import { BrowserRouter } from "react-router-dom";

import { Header, Footer } from "./components";
import MainNavigation from "./MainNavigation";
import { useState, useEffect } from "react";
import ReactGA from "react-ga";

function App() {
  const TRACKING_ID = "UA-236057483-1"; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  const [upArrow, setUpArrow] = useState(false);

  const handleNavigation = (e) => {
    const scrollPosition = window.pageYOffset;
    if (scrollPosition > 100) return !upArrow && setUpArrow(true);
    else return upArrow && setUpArrow(false);
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location]);

  useEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      // return a cleanup function to unregister our function since its gonna run multiple times
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [window.scrollY]);

  return (
    <div className="App">
      <BrowserRouter>
        <Header />

        <div class="entry-content">
          <MainNavigation />
        </div>
        <Footer />
      </BrowserRouter>

      {upArrow && (
        <div
          onClick={() => window.scroll(0, 0)}
          id="wpfront-scroll-top-container"
        >
          <img
            src="http://www.mercosur30anos.uy/wp-content/plugins/wpfront-scroll-top/images/icons/1.png"
            alt=""
          />
        </div>
      )}
    </div>
  );
}

export default App;
