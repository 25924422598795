import "../App.css";

const scheduleData = [
  {
    date: "4 de agosto",
    events: [
      {
        time: "14:00h",
        title: "Apertura",
        details: [
          {
            name: "Dra. Patricia Viera",
            affiliation: "CEPE/CENUR Noreste/UdelaR, Uruguay"
          },
          {
            name: "Profa. Gabriela Mordecki",
            affiliation: "IECON-FCEA UdelaR, Uruguay"
          }
        ]
      },
      {
        time: "14:20h",
        title: "Mesa redonda",
        description: "200 años de relacionamiento Brasil-Uruguay. Relaciones internacionales y situación política",
        details: [
          {
            name: "Dr. Camilo López Burian",
            affiliation: "RRII-FDER e ICP-FCS/UdelaR, Uruguay"
          },
          {
            name: "Dr. Renatho Costa",
            affiliation: "RI/Unipampa, Brasil"
          },
          {
            name: "Dra. Nastasia Barceló",
            affiliation: "PEI/UM-FCS/UdelaR, Uruguay"
          }
        ],
        moderator: {
          name: "Dra. Letícia Núñez Almeida",
          affiliation: "CEPE-CENUR Noreste/UdelaR, Uruguay"
        }
      },
      {
        time: "15:45h",
        title: "Pausa para café",
        isBreak: true
      },
      {
        time: "16:15h",
        title: "Mesa redonda",
        description: "Fronteras: problemas comunes y realidades diversas (pobreza, racismos). Educación, Salud y Derechos",
        details: [
          {
            name: "Dra. Helenara Silveira Fagundes",
            affiliation: "UFSC, Brasil"
          },
          {
            name: "Dra. Patricia Viera",
            affiliation: "CEPE/CENUR Noreste/UdelaR, Uruguay"
          },
          {
            name: "Dra. Letícia Núñez Almeida",
            affiliation: "CEPE/CENUR Noreste/UdelaR, Uruguay"
          }
        ],
        moderator: {
          name: "Dra. Nastasia Barceló",
          affiliation: "PEI-UM-FCS/UdelaR, Uruguay"
        }
      },
      {
        time: "18:00h",
        title: "Presentación de posters"
      }
    ]
  },
  {
    date: "5 de agosto",
    events: [
      {
        time: "14:00h",
        title: "Mesa redonda",
        description: "Territorio y Ciudad: Democracia y ciudadanía",
        details: [
          {
            name: "Dr. Tiaraju Salini",
            affiliation: "UFPEL, Brasil"
          },
          {
            name: "Mag. Yandira Alvarez",
            affiliation: "CUCEL/ CENUR Noreste/UdelaR, Uruguay"
          },
          {
            name: "Mag. María José Bolaña",
            affiliation: "ICP-FCS/UdelaR, Uruguay"
          }
        ],
        moderator: {
          name: "Dr. Camilo López Burian",
          affiliation: "RRII-FDER e ICP-FCS/UdelaR, Uruguay"
        }
      },
      {
        time: "15:45h",
        title: "Pausa para café",
        isBreak: true
      },
      {
        time: "16:15h",
        title: "Mesa redonda",
        description: "La coyuntura económica presente y futura",
        details: [
          {
            name: "Dr. André Moreira Cunha",
            affiliation: "UFRGS, Brasil"
          },
          {
            name: "Profa. Gabriela Mordecki",
            affiliation: "IECON-FCEA UdelaR, Uruguay"
          }
        ],
        moderator: {
          name: "Dr. Rafael Schmidt",
          affiliation: "RI/Unipampa, Brasil"
        }
      },
      {
        time: "18:00h",
        title: "Presentación de trabajos y divulgación del poster ganador del concurso."
      }
    ]
  }
];

const scheduleData2024 = [
  {
    date: "26 de setiembre",
    events: [
      {
        time: "9:00-10:00hs",
        title: "Acreditación y colocación de posters"
      },
      {
        time: "10:00-10:15hs",
        title: "Apertura",
        details: [
          {
            name: "Patricia Viera",
            affiliation: "CEPE, CENUR Noreste, UdelaR"
          },
          {
            name: "Gabriela Mordecki",
            affiliation: "IECON, FCEA, UdelaR"
          }
        ]
      },
      {
        time: "10:15-11:15hs",
        title: "Desigualdades e identidades. Desafíos en contextos fronterizos de Brasil-Uruguay",
        details: [
          {
            name: "Letícia Núñez Almeida",
            affiliation: "CEPE, CENUR Noreste, UdelaR",
            topic: "Educación y Fronteras: Self fronterizo, encrucijadas y Salud Mental."
          },
          {
            name: "Silvio Barboza",
            affiliation: "Universidade Federal de Santa Catarina (UFSC)",
            topic: "Trabalho e desigualdades: o emprego sem registro formal entre trabalhadores fronteiriços uruguaios na cidade-gêmea de Santana do Livramento/RS."
          }
        ],
        moderator: {
          name: "Pedro Russi",
          affiliation: "CENUR Litoral Norte, UdelaR"
        }
      },
      {
        time: "11:15-11:30hs",
        title: "Pausa para café y presentación de posters",
        isBreak: true
      },
      {
        time: "11:30-12:30hs",
        title: "Dinámicas fronterizas y territoriales en la región Pampa Brasil-Uruguay",
        details: [
          {
            name: "Camilo Pereira Carneiro",
            affiliation: "Universidad Federal de Goiás (UFG)",
            topic: "Frontera Brasil-Uruguay: interacciones espaciales, reclamos territoriales y políticas públicas de desarrollo."
          },
          {
            name: "Pablo Díaz Estévez",
            affiliation: "CENUR Noreste, UdelaR"
          },
          {
            name: "Fernando Aristimunho",
            affiliation: "Comitê dos Povos e Comunidades Tradicionais do Pampa",
            topic: "Territorios rurales e interculturalidad en el bioma pampa de Uruguay y Brasil."
          }
        ],
        moderator: {
          name: "Cassiane da Costa",
          affiliation: "Universidade Estadual do Rio Grande do Sul – UERGS"
        }
      },
      {
        time: "12:30-14:00hs",
        title: "Pausa",
        isBreak: true
      },
      {
        time: "14:00-15:30hs",
        title: "Cruzando fronteras: desafíos y oportunidades en educación e investigación",
        details: [
          {
            name: "Mauricio de Souza",
            affiliation: "CENUR Noreste, Udelar",
            topic: "Caracterización territorial de la frontera uruguayo-brasileña; aspectos históricos y sociales."
          },
          {
            name: "Patricia Viera",
            affiliation: "CEPE, CENUR Noreste, UdelaR",
            topic: "Internacionalización de la Educación Superior, democratización y multiculturalidad: políticas educativas en la frontera Uruguay-Brasil."
          },
          {
            name: "Cassiane da Costa",
            affiliation: "Universidade Estadual do Rio Grande do Sul – UERGS",
            topic: "Educa Pampa: cuidado com a Pampa e com suas gentes na Fronteira Brasil – Uruguai."
          }
        ],
        moderator: {
          name: "Letícia Núñez Almeida",
          affiliation: "CEPE, CENUR Noreste, UdelaR"
        }
      },
      {
        time: "15:30-16:00hs",
        title: "Pausa para café y presentación de posters",
        isBreak: true
      },
      {
        time: "16:00-17:30hs",
        title: "Mesa Redonda: Arte, religiosidad y espacios de encuentros en las relaciones Uruguay Brasil",
        details: [
          {
            name: "Pedro Russi",
            affiliation: "CENUR Litoral Norte, UdelaR"
          },
          {
            name: "Marcos Sari",
            affiliation: "Bellas Artes, CENUR Rivera, UdelaR"
          },
          {
            name: "Virginia Solanas",
            affiliation: "CENUR Rivera, UdelaR"
          }
        ],
        moderator: {
          name: "Elisa Cardozo",
          affiliation: "CENUR Rivera, UdelaR"
        }
      },
      {
        time: "17:30-18:00hs",
        title: "Pausa para café y presentación de posters",
        isBreak: true
      }
    ]
  },
  {
    date: "27 de setiembre",
    events: [
      {
        time: "9:00-10:30hs",
        title: "Un análisis de la coyuntura económica de Brasil y Uruguay",
        details: [
          {
            name: "André Moreira Cunha",
            affiliation: "UFRGS, Brasil",
            topic: "La coyuntura económica brasileña"
          },
          {
            name: "Gabriela Mordecki",
            affiliation: "FCEA, UdelaR",
            topic: "La coyuntura económica uruguaya"
          }
        ],
        moderator: {
          name: "Camilo López Burian",
          affiliation: "FDER-FCS, UdelaR"
        }
      },
      {
        time: "10:30-11:00hs",
        title: "Pausa para café y presentación de posters",
        isBreak: true
      },
      {
        time: "11:00-12:00hs",
        title: "La frontera como espacio y sus problemáticas",
        details: [
          {
            name: "Adriana Dorfman",
            affiliation: "UFRGS",
            topic: "Matriz de análise espacial na observação da fronteira Brasil e Uruguay."
          },
          {
            name: "Magali Ivañez",
            affiliation: "Facultad de Enfermería, UdelaR"
          },
          {
            name: "Sebastián Güida",
            affiliation: "CENUR Noreste, UdelaR",
            topic: "Derechos Humanos, migración y la situación de la trata de mujeres en la frontera Rivera - Santana do Livramento."
          }
        ],
        moderator: {
          name: "Letícia Núñez Almeida",
          affiliation: "CEPE, CENUR Noreste, UdelaR"
        }
      },
      {
        time: "12:00-14:00hs",
        title: "Pausa",
        isBreak: true
      },
      {
        time: "14:00-16:00hs",
        title: "Cómo nos vemos y qué futuro colectivo pensamos: las relaciones Uruguay Brasil en clave política.",
        details: [
          {
            name: "Rafael Balardim",
            affiliation: "Unipampa",
            topic: "Política Externa Brasileira: o esvaziamento e a reconstrução do caráter estratégico (2016-2024)"
          },
          {
            name: "Camilo López Burian",
            affiliation: "FDER-FCS, UdelaR",
            topic: "Brasil en la mirada de los partidos uruguayos. Evaluación del relacionamiento y proyecciones de futuro."
          },
          {
            name: "Flavio Lira",
            affiliation: "Unipampa",
            topic: "A política externa para o hidrogênio no Brasil e no Uruguai"
          }
        ],
        moderator: {
          name: "Camilo Pereira Carneiro",
          affiliation: "Universidad Federal de Goiás (UFG)"
        }
      },
      {
        time: "16:00-18:00hs",
        title: "Pausa para café y presentación de posters (divulgación del poster ganador del concurso)",
        isBreak: true
      }
    ]
  }
];

const renderSchedule = (schedule) => {
  return schedule.map((day, index) => (
    <div key={index}>
      <h3>{day.date}</h3>
      <br />
      <table className="has-fixed-layout">
        <tbody>
          {day.events.map((event, idx) => (
            <tr key={idx} style={event.isBreak ? { backgroundColor: "#eee" } : {}}>
              <td>{event.time}</td>
              <td className="has-text-align-left" data-align="left">
                <strong>{event.title}</strong>
                {event.description && <p>{event.description}</p>}
                {event.details && (
                  <>
                    {event.details.map((detail, i) => (
                      <div key={i}>
                        <strong>{detail.name}</strong>, {detail.affiliation}
                      </div>
                    ))}
                    {event.moderator && (
                      <div>
                        <label>Modera</label> - <strong>{event.moderator.name}</strong>, {event.moderator.affiliation}
                      </div>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <br/>
      <br/>
    </div>
  ));
};

const Programa = ({is2024}) => {
  return (
    <>
      <hr className="wp-block-separator" />
      <h1 className="has-text-align-left">PROGRAMA</h1>
      <br />
      {renderSchedule(is2024 ? scheduleData2024 :scheduleData)}
    </>
  );
};

export default Programa;