import "../App.css";

const Footer = () => {
  return (
    <>
      <footer id="colophon" class="site-footer responsive-max-width">
        <section id="block-20" class="widget widget_block">
          <hr class="wp-block-separator" />
          <div class="wp-block-columns">
            <div class="wp-block-column">
              <p>
                <a href="mailto:geibu.coordinacion@gmail.com">
                  <sub>GEIBU-Estudios Interdisciplinarios Brasil-Uruguay</sub>
                </a>
              </p>
            </div>

            <div class="wp-block-column">
              <p class="has-text-align-right">
                <sub
                  className="right-float
                  "
                >
                  Desarrollado por{" "}
                  <a
                    href="http://www.tmassuia.com/"
                    data-type="URL"
                    data-id="www.tmassuia.com"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Thiago Massuia
                  </a>
                </sub>
              </p>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
