import * as Photos from "../assets/images/expositores";

export const expositors = [
  {
    image: Photos.Andre_Cunha,
    name: "André Moreira Cunha",
    description:
      "Economista, Profesor titular en la Universidad Federal de Río Grande del Sul, Brasil, Doctorado en Ciencias Económicas por la Universidad Estatal de Campinas (2001) y postdoctorado por la Universidad de Cambridge (2012). Especializado en los temas: globalización, desarrollo económico, crisis financieras, integración económica y economías asiáticas.",
  },
  {
    image: Photos.Camilo_Burian,
    name: "Camilo López Burian",
    description:
      "Doctor en Ciencia Política (Universidad de la República - UdelaR). Profesor Adjunto en el Departamento de Ciencia Política de la Facultad de Ciencias Sociales y en el Grupo Docente de Política y Relaciones Internacionales de la Facultad de Derecho (Universidad de la República). Investigador Nivel 1 en el Sistema Nacional de Investigadores de Uruguay. Sus temas de especialización son: política exterior, relaciones internacionales, política de Brasil, derechas y política internacional.",
  },
  {
    image: Photos.Rafael_Schmidt,
    name: "Rafael Vitória Schmidt",
    description:
      "Professor Adjunto da Universidade Federal do Pampa (Unipampa), Campus de Sant'Ana do Livramento, onde atua nos Cursos de graduação em Relações Internacionais e em Direito, e no curso de Especialização em Relações Internacionais Contemporâneas. Doutor em Estudos Estratégicos Internacionais pela Universidade Federal do Rio Grande do Sul - UFRGS; Mestre em Integração Latino-Americana pela Universidade Federal de Santa Maria - UFSM e Bacharel em Direito pela Universidade Federal de Pelotas - UFPel. Atualmente é Coordenador do Curso de Relações Internacionais da Unipampa.",
  },
  {
    image: Photos.Gabriela_Mordecki,
    name: "Gabriela Mordecki",
    description:
      "Economista, docente e investigadora del Instituto de Economía de la Facultad de Ciencias Económicas y Administración de la Universidad de la República, FCEA-UdelaR), en el Grupo de Análisis Macroeconómico y Comercio. Especialista en temas de coyuntura económica y turismo, docente del Taller de Análisis de la Coyuntura y el Seminario de Economía Nacional de la Licenciatura en Economía de FCEA-UdelaR.",
  },

  {
    image: Photos.Helenara_Fagundes,
    name: "Helenara Silveira Fagundes",
    description:
      "Graduada em serviço social e doutora em Serviço social pela Pontifícia Universidade Católica do Rio Grande do Sul. Professora do Departamento em Serviço social da Universidade Federal de Santa Catarina. Coordenadora do Núcleo de estudos e Pesquisas - Estado, sociedade Civil, Politicas Públicas e Serviço social. Áreas de interesse em pesquisa: saúde, assistência social, fronteira e migração.",
  },

  {
    image: Photos.Leticia_nunes,
    name: "Letícia Núñez Almeida",
    description:
      "Abogada, docente e investigadora del Centro de Estudios sobre Políticas Educativas del Cenur Noreste, UdelaR. Doctora en Sociología por la Universidade de São Paulo, con magíster en Sociología en la Universidade Federal do Rio Grande do Sul UFRGS y posdoctorado en Relaciones Internacionales en la UFRGS. Especializada en los temas: fronteras internacionales, políticas públicas y Derechos Humanos.",
  },
  {
    image: Photos.Maria_Bolana,
    name: "María José Bolaña",
    description:
      "Docente de Historia egresada del Instituto de Profesores Artigas (IPA). Magister en Historia Rioplatense por la Facultad de Humanidades y Ciencias de la Educación de la Universidad de la República (FHCE-UdelaR). Doctoranda en Historia en la FHCE-UdelaR. Profesora asistente en el Departamento de Ciencia Política de la Facultad de Ciencias Sociales de UdelaR. Asistente en los cursos de Historia Contemporánea de América Latina y de Políticas de Vivienda.",
  },
  {
    image: Photos.Nastasia_Barcelą,
    name: "Nastasia Barceló",
    description:
      "Doctora en Ciencias Sociales por la Universidade de São Paulo (USP). Docente del Programa de Estudios Internacionales de la Facultad de Ciencias Sociales (UdelaR). Forma parte del Observatorio de Política Exterior Uruguaya (OPEU) y del Laboratorio de Estudos sobre o Brasil e o Sistema Mundial (Departamento de Historia-USP)",
  },

  {
    image: Photos.Patricia_Viera,
    name: "Patricia Viera Duarte",
    description:
      "Doctora en Educación con estudios posdoctorales en Políticas Educativas en UNTREF (Argentina). Investigadora del Sistema Nacional de Investigadores (SNI) de la Agencia Nacional de Investigación e Innovación de Uruguay. Profesora Adjunta del Centro de Estudios sobre Políticas Educativas Cenur Noreste de UdelaR. Directora del Campus Rivera del Centro Universitario Regional del Noreste de la Universidad de la República.",
  },
  {
    image: Photos.Renatho_Costa,
    name: "Renatho Costa",
    description:
      "Doctor en Historia Social por la Universidad de São Paulo (USP), investigador de la Universidad Al-Mustafa (Irán), profesor asociado en la Universidad Federal del Pampa (Unipampa). Investigador en los temas: seguridad internacional, política exterior de Estados Unidos y Medio Oriente.",
  },

  {
    image: Photos.Tiaraju_Salini,
    name: "Tiaraju Salini Duarte",
    description:
      "Geógrafo, Doctor en Geografía por la Universidad de São Paulo (USP) y docente en la Universidade Federal de Pelotas (UFPel). Coordinador del Grupo de Investigación GEOTER/CNPQ y del Laboratorio de Estudios Urbanos y Regionales/UFPel. Especializado en los siguientes temas: Ordenamiento territorial, Seguridad pública, narcotráfico y crimen organizado.",
  },
  {
    image: Photos.Yandira_Alvarez,
    name: "Yandira Álvarez Plada",
    description:
      "Lic. Trabajo Social, UdelaR. Maestranda en Género y Políticas de Igualdad - FLACSO Uruguay. Docente e investigadora del Centro de Estudios de la Frontera (CEF) Casa de la Universidad de Cerro Largo (CUCEL - UdelaR). Sus temas de investigación son vulnerabilidad social y construcción de ciudadanía en frontera , los movimientos sociales y políticas publicas de género.",
  },
];
