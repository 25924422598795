const Youtube = () => {

    return <>
        <hr class="wp-block-separator" />
        <figure class="wp-block-pullquote" >
            <blockquote>
                <p>Youtube(GEIBU - Estudios Interdisciplinarios Brasil - Uruguay): </p>
                < button
                    onClick={() =>
                        window.open(
                            "https://www.youtube.com/channel/UCHleU3SP3RLha0iGT4gxmcA",
                            "_blank",
                            "noopener,noreferrer"
                        )
                    }
                >
                    Canal Youtube
                </button>
            </blockquote>
        </figure>
    </>
}

export default Youtube;