import Expositores from "./Expositores";
import Home from "./Home";
import Intro from "./Intro";
import Logos from "./Logos";
import Programa from "./Programa";
import Youtube from "./Youtube";

const Jornada1 = () => {
    return (
        <div>
            <Intro />
            <Youtube />
            <Logos />
            <Programa />
            <Expositores />
        </div>
    )
}

export default Jornada1;