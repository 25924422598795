import * as Images from "../assets/images";

const Logos = () => {
    return <>
        <hr class="wp-block-separator" />
        <br />
        <h6>Los docentes promotores del evento pertenecen a:</h6>
        <div class="wp-block-columns">
            <div class="wp-block-column">
                <div class="wp-block-image is-style-default">
                    <img src={Images.Sociales} alt="Brasil 200 años" />
                </div>
            </div>
            <div class="wp-block-column">
                <div class="wp-block-image is-style-default">
                    <img src={Images.Derecho} alt="Brasil 200 años" />
                </div>
            </div>
            <div class="wp-block-column">
                <div class="wp-block-image is-style-default">
                    <img src={Images.Cenur} alt="Brasil 200 años" />
                </div>
            </div>
            <div class="wp-block-column">
                <div class="wp-block-image is-style-default">
                    <img src={Images.Economicas} alt="Brasil 200 años" />
                </div>
            </div>
            <div class="wp-block-column">
                <div class="wp-block-image is-style-default">
                    <img src={Images.CenurNorte} alt="Brasil 200 años" />
                </div>
            </div>
        </div>
    </>
}

export default Logos;