import "../App.css";
import * as Images from "../assets/images";


const Presentacion = () => {
  return (
    <>
      <h1 class="has-text-align-left">PRESENTACIÓN</h1>
      <div class="wp-block-columns">
        <div class="wp-block-column">
          <p class="has-text-align-left">
            El Grupo de estudios interdisciplinarios Brasil-Uruguay (GEIBU), formado en 2021, trabaja para desarrollarse como un espacio interdisciplinario de referencia de los estudios sobre Brasil y sobre las relaciones de Uruguay y Brasil en la Universidad de la República (UdelaR), a partir de diversos enfoques desde las distintas áreas de las ciencias sociales y las humanidades (Historia, Economía, Sociología, Ciencia Política y Relaciones Internacionales, Antropología, Cultura y Arte).
          </p>
        </div>

        <div class="wp-block-column">
          <figure class="wp-block-image size-large is-style-default">
            <img
              loading="lazy"
              width="554"
              height="1024"
              src={Images.OnlyFlags}
              alt=""
              class="wp-image-71"
              sizes="(max-width: 554px) 100vw, 554px"
            />
          </figure>
        </div>
      </div>
      <p class="has-text-align-left">
        Contacto: <strong><a href="mailto:geibu.udelar@gmail.com">geibu.udelar@gmail.com</a></strong>

      </p>
      <div class="wp-block-column">
        <figure class="wp-block-image size-large is-style-default">
          <img
            className="roundedFlag"
            loading="lazy"
            src={Images.Flags}
            alt=""
          />
        </figure>
      </div>
      <p class="has-text-align-left">
        El grupo está integrado por:
        <br />
        <br />
        <strong>Gabriela Mordecki (coordinadora)</strong>
        <br /> Instituto de Economía de la Facultad de Ciencias Económicas y
        Administración de la Universidad de la República, UdelaR.
        <br />
        <br />
        <strong>Camilo López Burian (coordinador)</strong>
        <br /> Departamento de Ciencia Política de la Facultad de Ciencias
        Sociales y Grupo Docente de Política y Relaciones Internacionales de la
        Facultad de Derecho, UdelaR.
        <br />
        <br />
        <strong>Diego Hernández Nilson</strong>
        <br /> Programa de Estudios Internacionales de la Facultad de Ciencias
        Sociales, UdelaR.
        <br />
        <br />
        <strong>Joaquín Torres</strong>
        <br /> Instituto de Economía de la Facultad de Ciencias Económicas y
        Administración de la Universidad de la República, UdelaR.
        <br />
        <br />
        <strong>Letícia Núñez Almeida</strong>
        <br /> Centro de Estudios sobre Políticas Educativas del Centro Universitario Regional Noreste, UdelaR.
        <br />
        <br />
        <strong>María José Bolaña</strong>
        <br /> Departamento de Ciencia Política de la Facultad de Ciencias Sociales,
        UdelaR.
        <br />
        <br />
        <strong>Nastasia Barceló</strong>
        <br /> Grupo Docente de Política y Relaciones Internacionales de la Facultad de Derecho, UdelaR.
        <br />
        <br />
        <strong>Pedro Russi</strong>
        <br /> Departamento de Ciencias Sociales del Centro Universitario Regional Litoral Norte, UdelaR.
        <br />
        <br />
      </p>
    </>
  );
};

export default Presentacion;
