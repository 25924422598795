import {
  Routes, //replaces "Switch" used till v5
  Route,
} from "react-router-dom";

import { Home, Jornada1, Jornada2 } from "./components";
const MainNavigation = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/inicio" element={<Home />} />
      <Route path="/2022" element={<Jornada1 />} />
      <Route path="/2024" element={<Jornada2 />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};

export default MainNavigation;
