const Andre_Cunha = require("./Andre_Cunha.jpg");
const Camilo_Burian = require("./Camilo_Burian.jpg");
const Gabriela_Mordecki = require("./Gabriela_Mordecki.jpg");
const Helenara_Fagundes = require("./Helenara_Fagundes.png");
const Maria_Bolana = require("./Maria_Bolana.jpg");
const Nastasia_Barcelą = require("./Nastasia_Barcelą.jpg");
const Patricia_Viera = require("./Patricia_Viera.jpg");
const Renatho_Costa = require("./Renatho_Costa.jpg");
const Tiaraju_Salini = require("./Tiaraju_Salini.png");
const Yandira_Alvarez = require("./Yandira_Alvarez.jpg");
const Rafael_Schmidt = require("./Rafael_Schmidt.jpg");
const Leticia_nunes = require("./Leticia_nunes.jpg");

export {
  Patricia_Viera,
  Andre_Cunha,
  Renatho_Costa,
  Tiaraju_Salini,
  Camilo_Burian,
  Gabriela_Mordecki,
  Helenara_Fagundes,
  Nastasia_Barcelą,
  Maria_Bolana,
  Yandira_Alvarez,
  Rafael_Schmidt,
  Leticia_nunes,
};
