const Cenur = require("./cenur.png");
const Sociales = require("./sociales.png");
const Economicas = require("./economicas.png");
const Derecho = require("./derecho.png");
const Header = require("./backgroundHeader.png");
const LogoUruguayBrasil = require("./logoUruguayBrasil.png");
const RoundedFlags = require("./roundedFlags.png");
const Flags = require("./flags.jpg");
const GEIBU_White = require("./GEIBU-Logo-white.png");
const CenurNorte = require("./cenurLogo.png");
const OnlyFlags = require("./logoUruguayBrasilFlags.png");

export {
  GEIBU_White,
  Cenur,
  Sociales,
  Economicas,
  Derecho,
  Header,
  LogoUruguayBrasil,
  RoundedFlags,
  CenurNorte,
  Flags,
  OnlyFlags,
};
