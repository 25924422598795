import Expositores from "./Expositores";
import Home from "./Home";
import Programa from "./Programa";

const Jornada2 = () => {
    return (
        <div>
            <Home is2024 /> 
            <Programa is2024 />
        </div>
    )
 }

 export default Jornada2;