import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import * as Images from "../assets/images";
import "../App.css";
import ReactGA from "react-ga";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  ReactGA.pageview(location.pathname);

  const [menu, setMenu] = useState(false);
  const handleMenu = () => {
    const menus = [
      {
        path: "inicio",
        text: "Inicio",
      },
      {
        path: "2022",
        text: "1\u00AA	Jornadas 2022",
      },
      {
        path: "2024",
        text: "2\u00AA Jornadas 2024",
      },
    ];

    return (
      <>
        {menus.map((item) => (
          <li
            key={item.path}
            id="menu-item-19"
            class={`menu-item menu-item-type-custom menu-item-object-custom menu-item-home ${
              "/" + item.path === location.pathname ||
              (location.pathname === "/" && item.path === "inicio")
                ? "current-menu-item"
                : ""
            }`}
          >
            <a
              onClick={() => {
                navigate(item.path);
                setMenu(false);
              }}
            >
              {item.text}
            </a>
          </li>
        ))}
      </>
    );
  };
  return (
    <>
      <body class="page-template-default page page-id-92 wp-custom-logo wp-embed-responsive singular image-filters-enabled">
        <div id="page" class="site">
          <div
            style={{
              backgroundImage: `url(${Images.Header})`,
            }}
            class="headerBackground"
          />
          <header id="masthead" class="site-header responsive-max-width">
            <div class="site-branding">
              <div class="site-logo">
                <a
                  onClick={() => navigate("/")}
                  class="custom-logo-link"
                  rel="home"
                >
                  <img src={Images.GEIBU_White} alt="Brasil 200 años" />
                </a>
              </div>
            </div>
            <nav
              id="site-navigation"
              class="main-navigation"
              aria-label="Main Navigation"
            >
              <label
                onClick={() => setMenu(!menu)}
                for="toggle"
                id="toggle-menu"
                class="button"
              >
                Menu <span class="hide-visually expanded-text">expanded</span>
                <span class="hide-visually collapsed-text">collapsed</span>
              </label>
              <div
                class={`${menu && "blockDisplay "} menu-primary-menu-container`}
              >
                <ul
                  id="menu-primary-menu"
                  class="main-menu"
                  aria-label="submenu"
                >
                  {handleMenu()}
                </ul>
              </div>
            </nav>
          </header>
          <div className="shadowTop" />

          <div id="content" class="site-content">
            <section id="primary" class="content-area">
              <header class="entry-header responsive-max-width"></header>
            </section>
          </div>
          <div class="headerLines">
            <svg
              id="curveUpColor"
              class="headerLines"
              version="1.1"
              width="100%"
              height="100"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <path d="M-20 100 C 0 0 10 20 100 100 Z"></path>
              <path
                fill="#ffe900"
                stroke="#ffe900"
                d="M-180 0 C 20 -20 80 0 120 90 Z"
              ></path>
              <path d="M-1 100 C 0 0 10 20 160 120 Z"></path>
            </svg>
          </div>
        </div>
      </body>
    </>
  );
};

export default Header;
