import "../App.css";
import { Intro, Logos, Presentacion, Youtube } from "../components";

const Home = ({is2024}) => {
  return (
    <>
      {is2024 ? <Intro is2024 /> :  <Presentacion/>}
      <Youtube />
      <Logos />
    </>
  );
};

export default Home;
