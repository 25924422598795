import * as Images from "../assets/images";
const Intro = ({is2024}) => {



const textData = !is2024 ? {
    title: "BRASIL 200 AÑOS:",
    subtitle: "Pasado, presente y futuro.",
    event: "JORNADA INTERDISCIPLINARIA",
    description: "Primeras jornadas interdisciplinarias de estudios y análisis sobre Brasil y las relaciones Uruguay-Brasil",
    date: "4 y 5 de agosto de 2022 - CENUR RIVERA",
    funding: "Financiado por el <br /><strong>Espacio Interdisciplinario de UdelaR</strong>"
  } : {
    title: "",
    subtitle: "Segundas Jornadas Interdisciplinarias Binacionales Uruguay/Brasil",
    event: "JORNADA INTERDISCIPLINARIA",
    description: "Segundas Jornadas Interdisciplinarias Binacionales Uruguay/Brasil",
    date: "26 y 27 de setiembre de 2024 - Sala Antel, Agraciada 333, Rivera, Uruguay",
    funding: "Financiado por el <br /><strong>Espacio Interdisciplinario de UdelaR</strong>"
  };
  

    return <>
        <h1>{textData.title}</h1>
        <div class="wp-block-columns">
            <div class="wp-block-column">
                <h3>{textData.subtitle}</h3>
                <p>{textData.event}</p>
                <p>{textData.description}</p>
                <div aria-hidden="true" className="wp-block-spacer"></div>
                <p>
                    <strong>{textData.date}</strong>
                </p>
                <p dangerouslySetInnerHTML={{ __html: textData.funding }}></p>
            </div>
            <div class="wp-block-column">
                <figure class="wp-block-image size-large is-style-default">
                    <img
                        loading="lazy"
                        width="554"
                        height="1024"
                        src={Images.RoundedFlags}
                        alt=""
                        class="wp-image-71"
                        sizes="(max-width: 554px) 100vw, 554px"
                    />
                </figure>
            </div>
        </div>
        <br /></>
}
export default Intro;